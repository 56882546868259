window.jQuery = window.$ = require('jquery');

require('bootstrap');
require('jquery-colorbox');
require('slick-carousel');
import videojs from 'video.js';
window.videojs = videojs;


$(function() {
	function toggleNav(){
	  var chkbox = document.getElementById("mobile-toggle");
	  chkbox.checked = !chkbox.checked;
      //console.log('rest');
        if(chkbox.checked) $('.mobile-toggle').addClass('opening');
        else $('.mobile-toggle').removeClass('opening');
	}
	var nav = document.getElementById('main-nav');
    nav.addEventListener('click', toggleNav);


    $("a[href^='tel']").on("click",function(){
        gtag('event', 'call', { event_category: 'click',  event_label: 'call', value: 200 });
        gtag('event', 'conversion', { 'send_to': 'AW-442523601/6HDmCMW0ofYBENG_gdMB' });
    });

    $("a[href*='line.me']").on("click",function(){
        gtag('event', 'line', { event_category: 'click',  event_label: 'line', value: 100});
        gtag('event', 'conversion', {'send_to': 'AW-442523601/2n9vCPCsofYBENG_gdMB'});
    });

    $("a[href*='facebook.com']").on("click",function(){
        gtag('event', 'facebook', { event_category: 'click',  event_label: 'facebook', value: 100});
        gtag('event', 'conversion', {'send_to': 'AW-442523601/73bKCPTGwfYBENG_gdMB'});
    });
});

$('.responsive-video').each(function(){
    var id = Math.random().toString(36).substr(2, 9);
    $(this).attr('id', 'video-' + id);
    $(this).addClass('video-js')
    videojs("video-" + id, {
        techOrder: ['html5'],
        autoplay: false,
        width: 640,
        height: 480,
        controls: true,
        fluid: true
    });
});

$('a.display-image').colorbox({maxWidth:'95%',maxHeight:'95%'});

import AOS from 'aos';
AOS.init();

$('.crafty-block-slideshow').slick({
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500
});

$('.home-benefit-list .crafty-block-list').slick({
    dots: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: false,
                dots: true
            }
        }, {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                dots: true
            }
        }
    ]
});


$('.home-benefit-list').each(function(){
    let height = 0;
    $('.crafty-block-list-item',$(this)).each(function(){
        if($(this).innerHeight()>height)height = $(this).innerHeight();
    });
    console.log('h:'+height);
    $('.crafty-block-list-item',$(this)).each(function(){
        console.log($(this).innerHeight())
        $(this).css({height:height+40});
    });
});
